import {Container} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {apiFetchQuery} from "../api";
import {useSearchParams} from "react-router-dom";
import Error500 from "./Error500";
import {useEdition} from "../contexts/EditionContext";
import {Skeleton} from "@mui/material";
import EventList from "../components/EventList";
import EventGrid from "../components/EventGrid";
import EventListHeader from "../components/EventListHeader";

export default function Events({isYouthCategory, utilities}) {
    let breadcrumbItem = [
        {'link': '#', name: !isYouthCategory ? 'Programme' : 'Festival jeunesse'}
    ];
    useEffect(() => {
        utilities.setDocumentTitle(!isYouthCategory ? 'Les événements' : 'Festival jeunesse');
        utilities.setLocation('events');
        utilities.setBreadcrumbItems(breadcrumbItem);
    }, []);

    const {contextEditionData} = useEdition();
    const [searchParams, setSearchParams] = useSearchParams();
    const [repoEventsHash, setRepoEventsHash] = useState([contextEditionData, searchParams]);

    useEffect(() => {
        setRepoEventsHash([contextEditionData, {
            'date': searchParams.get('date'),
            'category': searchParams.get('category'),
            'isYouthCategory': isYouthCategory
        }]);
    }, [contextEditionData, searchParams, isYouthCategory])

    const {
        data,
        error,
        isLoading
    } = useQuery(['repoEventsData', repoEventsHash], () => apiFetchQuery('get-events', {
        'edition': contextEditionData.currentEdition.id,
        'date': searchParams.get('date'),
        'category': searchParams.get('category'),
        'isYouthCategory': isYouthCategory
    }), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
        onSuccess: (data) => {
            if (data.currentCategory !== undefined && data.currentCategory.id !== null) {
                breadcrumbItem.push({
                    'link': !isYouthCategory ? '/les-evenements' : '/festival-jeunesse' + `?category=` + data.currentCategory.id,
                    'name': data.currentCategory.name
                });
            }
            if (data.currentDate !== undefined && data.currentDate.id !== null) {
                breadcrumbItem.push({
                    'link': !isYouthCategory ? '/les-evenements' : '/festival-jeunesse' + `?category=` + data.currentCategory.id + `&date=` + data.currentDate.id,
                    'name': data.currentDate.name
                });
            }
            utilities.setBreadcrumbItems(breadcrumbItem);
        }
    });

    if (error) {
        return <Error500 utilities={utilities}/>
    }

    return (
        <>
            {isLoading ? (
                <Container>
                    <div className={`sub-container`}>
                        <EventListHeader contextEditionData={contextEditionData}/>
                        <div className={`mt-5`}>
                            <h2 className="text-uppercase mb-4 fw-bold fs-4"><Skeleton width={200}/></h2>
                            <h4 className="mb-3">
                                <Skeleton style={{display: "inline-block", width: '260px'}}/> - <Skeleton
                                style={{display: "inline-block", width: '160px'}}/>
                                <Skeleton/>
                                <Skeleton/>
                                <Skeleton width={60}/>
                            </h4>
                            <h4 className="mb-3">
                                <Skeleton style={{display: "inline-block", width: '260px'}}/> - <Skeleton
                                style={{display: "inline-block", width: '160px'}}/>
                                <Skeleton/>
                                <Skeleton/>
                                <Skeleton width={60}/>
                            </h4>
                            <h4 className="mb-3">
                                <Skeleton style={{display: "inline-block", width: '260px'}}/> - <Skeleton
                                style={{display: "inline-block", width: '160px'}}/>
                                <Skeleton/>
                                <Skeleton/>
                                <Skeleton width={60}/>
                            </h4>
                        </div>
                    </div>
                </Container>
            ) : (
                <>
                    {data.currentCategory.viewMode === 'grid' ? (
                        <>
                            <Container>
                                <EventListHeader contextEditionData={contextEditionData} data={data}/>
                            </Container>
                            <Container>
                                <EventGrid contextEditionData={contextEditionData} data={data}/>
                            </Container>
                        </>
                    ) : (
                        <Container>
                            <EventListHeader contextEditionData={contextEditionData} data={data}/>
                            <div className={`sub-container`}>
                                <EventList contextEditionData={contextEditionData} data={data}/>
                            </div>
                        </Container>
                    )}

                    {data.eventsByDates.length === 0 && (
                        <Container>
                            <div className={`alert alert-info bg-white`}>
                                Aucun événement de cette catégorie n’est programmé à cette date.
                            </div>
                        </Container>
                    )}
                </>
            )}
        </>
    );
}
