import React, {useEffect, useState} from 'react';
import logoLink from "../assets/images/logo.png";
import {Col, Container, Row} from "react-bootstrap";

import '../assets/styles/Home.css';
import {apiFetch, apiFetchQuery} from "../api";
import HomeBlockNews from "../components/HomeBlockNews";
import HomeBlockEdito from "../components/HomeBlockEdito";
import HomeBlockPartner from "../components/HomeBlockPartner";
import HomeBlockAuthors from "../components/HomeBlockAuthors";
import {useQuery} from "react-query";
import Error500 from "./Error500";
import {Skeleton} from "@mui/material";
import {Link} from "react-router-dom";
import FormNewsletter from "../components/FormNewsletter";
import HomeBlockVideo from "../components/HomeBlockVideo";

export default function Home({utilities}) {
    const [height, setHeight] = useState(200);

    const handleResize = () => {
        let $width = document.getElementById("homeLogoWrapper").offsetWidth;
        let $height = $width * 770 / 1366;
        setHeight($height);
    }
    useEffect(() => {
        utilities.setDocumentTitle('Festival america');
        utilities.setLocation('home');
        utilities.setBreadcrumbItems([]);
        handleResize();
        window.addEventListener("resize", handleResize, false);
    }, []);



    const {data, error, isLoading} = useQuery('repoHomeData', () => apiFetchQuery('get-home-configuration', {}),{
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1,
    });
    if (error) {
        return <Error500/>
    }

    return (
        <div>
            <Container className={`p-0`}>
                <div id={`homeLogoWrapper`} className={`header`} style={{height: height + 'px'}}>
                    <div id={`homeHeaderCms`} className={`mx-md-5 fw-bold`}>
                        <div className={`dates`}>{isLoading ?
                            <Skeleton variant={"text"} className={`bg-white`}/> : data.homeHeaderDates}</div>
                        <div className={`text`}>{isLoading ?
                            <Skeleton variant={"text"} className={`bg-white`}/> :
                            <span dangerouslySetInnerHTML={{__html: data.homeHeaderText}}></span>}</div>
                    </div>
                </div>
            </Container>

            {!isLoading && (data.homeLinkProgram || data.homeLinkTicket) && (
                <Container className={`mt-6`}>
                    <div className={`mx-md-5`}>
                        <Row>
                            {!isLoading && data.homeLinkProgram && (
                                <Col>
                                    <Link className="btn bg-dark" to={data.homeLinkProgram}>Découvrez le
                                        programme&nbsp;&nbsp;></Link>
                                </Col>
                            )}

                            {!isLoading && data.homeLinkTicket && (
                                <Col>
                                    <Link className="btn bg-red" to={data.homeLinkTicket}>Achetez vos
                                        billets&nbsp;&nbsp;></Link>
                                </Col>
                            )}

                        </Row>
                    </div>
                </Container>
            )}

            <Container className={`mt-6`}>
                <HomeBlockNews/>
            </Container>

            <HomeBlockEdito bg={isLoading ? `#feed01` : data.homeBgBloc2}/>

            <HomeBlockAuthors bg={isLoading ? `#e42886` : data.homeBgBlocAuthors}/>


            <Container className={`mt-6 bg-yellow py-5`}>
                <FormNewsletter/>
            </Container>

            {!isLoading && (data.homeLinkLearnMore || data.homeLinkVolunteer) && (
                <Container className={`mt-6`}>
                    <Row className=" mx-md-5 ">
                        {!isLoading && data.homeLinkLearnMore && (
                            <Col><a href={data.homeLinkLearnMore} className="btn bg-red" target={`_blank`}
                                    download={``}>Téléchargez le programme&nbsp;&nbsp;></a></Col>
                        )}

                        {!isLoading && data.homeLinkVolunteer && (
                            <Col className="col">

                                <Link
                                    to='#'
                                    onClick={(e) => {
                                        window.location.href = data.homeLinkVolunteer;
                                        e.preventDefault();
                                    }}
                                    className="btn bg-blue"
                                >
                                    Devenir
                                    bénévole&nbsp;&nbsp;></Link></Col>
                        )}
                    </Row>
                </Container>
            )}



            <HomeBlockVideo bg={isLoading ? `#e2001a` : `#e2001a`}/>

            <Container className={`mt-6`}>
                <HomeBlockPartner/>
            </Container>
        </div>
    );

}
