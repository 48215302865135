import {Col, Container, Row} from "react-bootstrap";
import {apiFetchQuery} from "../api";
import {useQuery} from "react-query";
import {useEdition} from "../contexts/EditionContext";
import ParticipantCard from "./ParticipantCard";
import Error500 from "../pages/Error500";
import SkeletonParticipantCard from "../skeletons/SkeletonParticipantCard";
import {Link} from "react-router-dom";
import React from "react";

export default function HomeBlockAuthors()
{
    const {contextEditionData} = useEdition();
    const {data, error, isLoading} = useQuery(['repoHookBlockAuthors', contextEditionData], () => apiFetchQuery('get-authors', {role: 'AUT', 'max-result': 8, 'chunk': 4, 'shuffle': 1, 'get-authors': (contextEditionData.lastEdition !== undefined ? contextEditionData.lastEdition.id : null)}), {
        refetchOnWindowFocus: process.env.NODE_ENV === 'development',
        retry: 1
    });
    if(error)
    {
        return <Error500 />
    }

   return (
     <div id={`bloc-authors`} className={`mt-6 pt-3`}>

         {isLoading
             ?
             (
                 <div>
                     <Container >
                         <h3 className={`mx-md-5 mb-5 text-uppercase`}>Les invités {contextEditionData.lastEdition !== undefined && contextEditionData.lastEdition.name}</h3>
                     </Container>
                     <Container className={`bg-white`}>
                         <div className={`mx-md-5`}>
                             <Row >
                                 <Col xs={6} sm={3}><SkeletonParticipantCard bg={`bg-dark`} /></Col>
                                 <Col xs={6} sm={3}><SkeletonParticipantCard bg={`bg-dark`} /></Col>
                                 <Col xs={6} sm={3}><SkeletonParticipantCard bg={`bg-dark`} /></Col>
                                 <Col xs={6} sm={3}><SkeletonParticipantCard bg={`bg-dark`} /></Col>
                             </Row>
                         </div>
                     </Container>
                     <Container className={`bg-pink`}>
                         <div className={`mx-md-5`}>
                             <Row >
                                 <Col xs={6} sm={3}><SkeletonParticipantCard bg={`bg-white`} /></Col>
                                 <Col xs={6} sm={3}><SkeletonParticipantCard bg={`bg-white`} /></Col>
                                 <Col xs={6} sm={3}><SkeletonParticipantCard bg={`bg-white`} /></Col>
                                 <Col xs={6} sm={3}><SkeletonParticipantCard bg={`bg-white`} /></Col>
                             </Row>
                         </div>
                     </Container>
                 </div>
             )
             :
             (
                 <div>
                     {data.length > 0 && (
                     <Container >
                         <h3 className={`mx-md-5 mb-5 text-uppercase`}>Parmi les invités</h3>
                     </Container>
                     )}
                     {data.map(item => (
                         <Container key={item.key} className={item.index % 2 != 0 ? `bg-pink mt-4 py-5 text-white` : `bg-white pb-3`}>
                             <div className={`mx-md-5`}>
                                 <Row className={`gy-3`}>
                                     {item.participants.map(participant => (
                                         <Col xs={6} sm={3} key={participant.id}><ParticipantCard participant={participant}/></Col>
                                     ))}
                                 </Row>
                             </div>
                         </Container>
                     ))}
                     {data.length > 0 && (
                         <Container className={`mt-6`}>
                             <Row>
                                 <Col md={3}></Col>
                                 <Col><Link className="btn bg-dark" to="/les-auteurs">Découvrez tous les invités {contextEditionData.currentEdition !== undefined && contextEditionData.currentEdition.name}&nbsp;&nbsp;></Link></Col>
                                 <Col md={3}></Col>
                             </Row>
                         </Container>
                     )}
                 </div>
             )

         }



     </div>
   );
}
