import {Container} from "react-bootstrap";
import React, {useState, useEffect} from 'react';
import {useEdition} from "../contexts/EditionContext";

export default function AppBando({utilities}){
    const {contextEditionData} = useEdition();
    let showBando = true;

    /*
    utilities.breadcrumbItems.map(item => {
        if (item.name === 'Événements !')
            showBando = false;
    });
    */

    return (
        <>
        {utilities.location === 'events' && contextEditionData.currentEdition !== undefined && contextEditionData.currentEdition.id === contextEditionData.lastEdition.id && showBando &&
                <Container className={`AppBando`}>
                    <div className={`sub-container`}>
                        <h1 className="text-uppercase">Programme </h1>
                        <div className={`bandoText text-uppercase`}>26 / 29 sept 2024 / vincennes</div>
                    </div>
                </Container>
        }
        </>
    );
}
